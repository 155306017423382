
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter } from "vue-router";
import variables from "@/router/api";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import GoBackButton from "@/components/reusable/GoBackButton.vue";
import "viewerjs/dist/viewer.css";

export default defineComponent({
  name: "motor-insurance-policy-details",
  components: { GoBackButton },
  // props: {
  //   profilePublicId: String,
  // },
  data() {
    return {
      publicId: this.$route.params.publicId,
      policyDetailsSummary: {},
      avatarUrl: "",
      imageURL: "",
      route: ""
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Vehicle Details", [
        "Cooperative",
        "Policies",
        "Policy Information",
        "Members Policies"
      ]);
    });
  },
  created() {
    const router = useRouter();

    // Check if publicId is passed as a prop
    if (this.publicId == null) {
      router.go(-1);
    }

    // Policy images route
    this.imageURL = variables.POLICY_IMAGES_ROUTE;

    // Get the details of the vehicle in schdule list
    this.getVehicleDetails();

    //Set page title
    document.title =
      "Cooperative: Vehicle Schedule Details | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    getVehicleDetails() {
      //Get the vehicle schedule details
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(
          `${variables.COOPERATIVE_MEMBER_ROUTE}/${this.publicId}/vehicles`
        )
          .then(({ data }) => {
            //Assign data to corresponding fields
            this.policyDetailsSummary = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
            // router.go(-1);
          });
      }
    },
    imageUrlAlt(event) {
      event.target.src = "/media/avatars/no-photo-available.png";
    }
  }
});
